import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxMaskService } from 'ngx-mask';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Period } from '../domains/period';

@Injectable({providedIn: 'root'})
export class NotificationService {
  globalOptions: Partial<IndividualConfig> = {
    tapToDismiss: true,
    closeButton: false,
    positionClass: 'toast-top-center-custom',
    timeOut: 4000,
    toastClass: 'toast-no-icon',
    enableHtml: true
  };

  constructor(
    public toastr: ToastrService,
    private deviceDetectorService: DeviceDetectorService,
    private ngxMaskService: NgxMaskService,
  ) {
  }

  private centerToastr(title: string, message: string, colorClass: string, timeout = 0) {
    const localOptions = Object.assign({}, this.globalOptions);

    localOptions.toastClass = `toast-no-icon ${colorClass}`;

    if (timeout > 0) {
      localOptions.timeOut = timeout;
    }

    this.toastr.show(message, title, localOptions);
    const toastrContainer = document.querySelector('#toast-container');
    toastrContainer.classList.add('toast-modal');

    const toast = this.toastr.toasts.find(toastI => toastI.toastId === this.toastr.currentlyActive);

    if (toast !== undefined) {
      toast.onHidden.subscribe(() => {
        toastrContainer.classList.remove('toast-modal');
      });
    }
  }

  /***
   * @param title
   * @param message
   * @param timeout number in milliseconds
   * @private
   */
  private centerToastrDark(title: string, message: string, timeout = 0) {
    this.centerToastr(title, message, 'toast-dark', timeout);
  }

  private centerToastrError(title: string, message: string) {
    this.centerToastr(title, message, 'toast-error');
  }

  private centerToastrSuccess(title: string, message: string) {
    this.centerToastr(title, message, 'toast-success');
  }

  centerToastrPrimary(title: string, message: string, timeout = 0) {
    this.centerToastr(title, message, 'toast-primary', timeout);
  }

  clear() {
    this.toastr.clear();
  }

  checkoutSuccess() {
    this.centerToastrPrimary('Your Mbership is active', 'Welcome to the Club!');
  }

  makeSelection() {
    this.centerToastrPrimary('Slo yo roll...', 'Complete your selections');
  }

  editMode() {
    this.centerToastrPrimary(
      'Confirm category before starting',
      'If you are the owner check your email to unlock admin features',
      3000
    );
  }

  passwordResetLinkSent(message: string) {
    this.centerToastrPrimary('Password reset link sent to', message);
  }

  loginError() {
    this.centerToastrPrimary('Incorrect email or password', '', 20000);
  }

  passwordUpdated() {
    this.centerToastrPrimary('', 'Password updated!');
  }

  invalidConfirmLink() {
    this.centerToastrPrimary('Error', 'Invalid or expired confirmation link', 5000);
  }

  emailExists() {
    this.centerToastrPrimary('', 'This email is associated with another account');
  }

  signupEmailExists() {
    this.centerToastrPrimary('', 'That email is taken. Try another.');
  }

  confirmationEmailSent(email: string) {
    this.centerToastrPrimary('Confirmation link sent to', email);
  }

  confirmationEmailWhenUserUpdate() {
    this.centerToastrPrimary('Check Your Email', 'To complete verification');
  }

  advisoryToast() {
    setTimeout(() => {
      this.centerToastrPrimary(
        'Consumer Advisory',
        'Consuming raw or undercooked meats, poultry, seafood, shellfish, or eggs may increase your risk of' +
        ' foodborne illness, especially if you have certain medical conditions',
        5000);
    }, 100);
  }

  subscription(isUpdate = true) {
    let message = `Successfully ${isUpdate ? 'updated' : 'canceled'}!`;

    if (!isUpdate) {
      message += '<br/>Your Mbership will not auto renew';
    }

    this.centerToastrPrimary('Subscription', message);
  }

  temporaryToast() {
    setTimeout(() => {
      this.centerToastrPrimary('This feature not yet activated', 'We\'ll email you when it\'s ready');
    }, 100);
  }

  orderingNotEnabled() {
    this.centerToastrPrimary('Place order via your server', '');
  }

  noChangesToPublish() {
    setTimeout(() => {
      this.centerToastrPrimary('No changes to publish', null);
    }, 100);
  }

  categoryChangeSubmitted() {
    this.centerToastrPrimary('Category change submitted', 'Thanks for your help!');
  }

  reportClosureSubmitted() {
    this.centerToastrPrimary('Closure notice submitted', 'Thanks for your help!');
  }

  reportClosureExists() {
    this.centerToastrPrimary('Closure notice submitted', 'Thanks for your help!');
  }

  reportIncompleteSubmitted() {
    this.centerToastrPrimary('Incomplete notice submitted', 'Thanks for your help!');
  }

  reportIncompleteExists() {
    this.centerToastrPrimary('Incomplete notice submitted', 'Thanks for your help!');
  }

  reportSubmitted() {
    this.centerToastrPrimary('Your report submitted', 'Thanks for your help!');
  }

  mberFavorites() {
    this.centerToastrPrimary('Favorites from fellow Mbers', 'Coming soon!');
  }

  suggestionSubmitted() {
    this.centerToastrPrimary('Your suggestion submitted', 'Thanks for your help!');
  }

  claimOwnerMessage() {
    this.centerToastrPrimary(
      'Get M powered',
      'Check your email for ownership verification instructions to gain admin rights & features',
      10000
    );
  }

  galleryUploadDisabled() {
    this.centerToastrPrimary(
      '',
      'The photo journaling feature for this example restaurant is disabled',
      5000
    );
  }

  publishedByOwner() {
    this.centerToastrPrimary('Published!', 'Use switcher to view/edit');
  }

  publishedByFan() {
    this.centerToastrPrimary('Published!', 'Use switcher to view/edit');
  }

  changesPublished() {
    this.centerToastrPrimary('Edits Published', 'Thanks for being a HERO!');
  }

  noAllergensToast() {
    setTimeout(() => {
      this.centerToastrPrimary(
        'Allergens unconfirmed',
        'Ask restaurant to enable',
        5000
      );
    }, 100);
  }

  phoneNotProvided() {
    this.centerToastrPrimary('Phone', 'Not currently provided');
  }

  emailNotProvided() {
    this.centerToastrPrimary('Email', 'Not currently provided');
  }

  embedError() {
    this.centerToastrPrimary('Unable to link', 'Try YouTube TikTok Rumble');
  }

  passwordUpdatedAfterReset() {
    this.centerToastrPrimary('Password', 'Successfully updated!');
  }

  emailConfirmed() {
    this.centerToastrPrimary('Your email is confirmed', '');
  }

  restaurantCrated() {
    this.centerToastrPrimary(null, 'This journal will be visible to everyone once approved');
  }

  confirmOwnershipEmail() {
    this.centerToastrPrimary('Check your email', 'For your owner verification instructions');
  }

  startJournalDesktop() {
    this.centerToastrPrimary(
      'Fill out a menu on & create a photo journal on mobile',
      'Desktop version coming soon'
    );
  }

  addRestaurant() {
    this.centerToastrPrimary('Thanks for sumbitting!', 'We\'ll email you upon approval');
  }

  addRestaurantDesktop() {
    this.centerToastrPrimary('Add restaurant on mobile', 'Desktop version coming soon');
  }

  followsDesktop() {
    this.centerToastrPrimary('', 'Follows coming soon!');
  }

  cameraDesktop() {
    this.centerToastrPrimary('Add photos', 'On mobile site');
  }

  heroDesktop() {
    this.centerToastrPrimary('Fill out menu on mobile web', 'Desktop version coming soon');
  }

  showCopied() {
    this.centerToastrPrimary('', 'Go forth & paste');
  }

  showHomeToastr() {
    this.centerToastrPrimary('', 'Your feed is coming soon!');
  }

  welcome(firstname: string, lastname: string) {
    this.centerToastrPrimary('Welcome!', `${firstname} ${lastname}`, 2000);
  }

  statsToastr() {
    this.centerToastrPrimary('Enagagement Score', 'Total photos + Total reviews');
  }

  titleToastr() {
    this.centerToastrPrimary('', 'You found an easter egg');
  }

  dividerToastr() {
    this.centerToastrPrimary('Mbers only feature', 'Coming soon!');
  }

  onClickCamera() {
    this.centerToastrPrimary('Add photo using mobile web ', '');
  }

  photoToastr() {
    this.centerToastrPrimary('Mber\'s Wall of Fame',
      'Coming soon!');
  }

  onSubmitClicked() {
    this.centerToastrPrimary('Thanks for your help!', 'Thanks for being an Mbassador');
  }

  emptyFavoriteBgClicked() {
    this.centerToastrPrimary('MmMm', 'Your to do list is empty…');
  }

  targetLineClicked() {
    this.centerToastrPrimary('MmMm', 'Your to do list is empty...');
  }

  videoAlreadyExists() {
    this.centerToastrPrimary('This video has already been submitted', 'Try another');
  }

  videoLinkInvalid() {
    this.centerToastrPrimary('Invalid link', 'Try another');
  }

  addPhotoClicked() {
    this.centerToastrPrimary('Add photo on mobile web', 'Desktop version coming soon');
  }

  settingsAndCameraClicked() {
    this.centerToastrPrimary('Disabled for Demo', 'M power a restaurant <br> \n' + '\n Tell your favorite owner!');
  }

  phoneClicked(restaurant) {
    const localOptions = Object.assign({}, this.globalOptions);

    localOptions.disableTimeOut = true;
    localOptions.toastClass = `toast-no-icon toast-primary`;

    return this.toastr.show(
      '',
      this.ngxMaskService.applyMask(restaurant.nationalPhoneNumber, '(000) 000-0000'),
      localOptions
    );
  }

  addPeriodClicked(period: Period) {
    this.centerToastrPrimary(
      period.eventTitle, period.timeStart + ' - ' +
      (period.timeEnd !== null ? period.timeEnd : '') + '</br>' +
      (period.eventDetails ? period.eventDetails : '')
    );
  }

  onAllergensClicked() {
    this.centerToastrPrimary(
      'Contaminant Warning', 'Shared kitchen equipment prevents the' +
      'complete absence of allergens <br> <br> ' +
      '<b>Consumer Advisory</b> <br> ' +
      '*Consuming raw or undercooked meats,' +
      'poultry, seafood, shellfish, or eggs may' +
      'increase your risk of foodborne illness,' +
      'especially if you have certain medical ' +
      'conditions <br> <br> ' +
      '<b>Photos</b> <br> ' +
      'May be subject to copyright'
    );
  }

  onAllergensDesktopClicked(html: string) {
    this.toastr.show(html, '', {
      enableHtml: true,
      positionClass: 'toast-top-center-custom',
      toastClass: 'toast-allergen'
    });
  }

  onNoAllergensDesktopClicked(){
    this.centerToastrPrimary('Allergens unconfirmed', 'Ask restaurant to enable');
  }

  onFilterClicked(){
    this.centerToastrPrimary('Filter by', 'Coming soon!');
  }

  locationDisabled() {
    const deviceInfo = this.deviceDetectorService.getDeviceInfo();
    let message;

    if (deviceInfo.os === 'iOS') {
      message = 'Settings > Privacy & Security > Location Services > ' + deviceInfo.browser;
    } else if (deviceInfo.os === 'Android') {
      message = 'Settings > Location > Location Access';
    }

    this.centerToastrPrimary('Location Disabled', message);
  }
}
