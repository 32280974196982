<div *appIfIsMobile class="classic-food-controls-mobile px-4 d-flex flex-column">
  <div class="flex-content-vc py-2">
    <ng-container *ngTemplateOutlet="buttons"></ng-container>
  </div>

  <p
    *ngIf="food.description && (!food.sections.length || (food.sections.length && !food.sectionsOpen))"
    class="mb-0 text-dark"
  >
    {{ food.description | commaToDot | andToAmpersand }}&nbsp;
    <a *ngIf="food.isAdvisory" appHrefNone class="position-absolute asterisk">
      *
    </a>
  </p>

  <div *ngIf="food.sections.length && food.sectionsOpen" class="section">
    <div *ngFor="let section of food.sections" class="mt-4 text-dark">
      <app-section-view [section]="section"></app-section-view>
    </div>
  </div>
</div>

<ng-container *appIfIsDesktop>
  <ng-container *ngIf="!clickOnAllergen">
    <div
      [appBackgroundUrl]="backgroundImage"
      class="w-100 h-100"
    >
      <div
        [ngClass]="{'title-clicked': titleHover}"
        class="backdrop-filter-classic position-absolute-0"
      ></div>

      <div class="p-classic w-100 h-100 d-flex flex-column">
        <div class="align-items-center text-center z-index-0">
          <span
            *ngIf="!restaurant.details.logo"
            (click)="openRestaurantModal()"
            (mouseover)="titleHover = true"
            (mouseleave)="titleHover = false"
            class="font-weight-500 font-size-1-3rem text-white cursor-pointer"
          >
            {{ restaurant.nameView }}
          </span>

          <div
            class="restaurant-desktop-header-logo-img align-self-center cursor-pointer white-shadow"
            (click)="openRestaurantModal()"
            (mouseover)="titleHover = true"
            (mouseleave)="titleHover = false"
          >
            <img
              *ngIf="restaurant.details.logo"
              appBlurImg
              [path]="restaurant.details.logo.path"
              [options]="{w: restaurant.details.logo.width, h: restaurant.details.logo.height}"
              [title]="restaurant.nameView"
              [alt]="restaurant.nameView"
            />
          </div>
        </div>

        <div class="m-auto"></div>

        <div *ngIf="!titleHover" class="z-index-1">
          <p
            *ngIf="food.description"
            class="mb-0-85rem text-white"
          >
            {{ food.description | commaToDot | andToAmpersand }} &nbsp;
            <a *ngIf="food.isAdvisory" appHrefNone class="position-absolute asterisk text-white">
              *
            </a>
          </p>

          <ng-container *ngTemplateOutlet="buttons"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <div
    *ngIf="clickOnAllergen"
    (mouseleave)="clickOnAllergen = false"
    class="classic-food-controls-desktop d-flex flex-column allergens h-100"
  >
    <app-allergens
      [food]="food"
      [allergens]="allergens"
      [restaurant]="restaurant"
      class="h-100 bg-dark"
    ></app-allergens>
  </div>
</ng-container>

<ng-template #buttons>
  <div [ngClass]="{'title-hovered': titleHover}" class="classic-food-buttons flex-content-vc bg-transparent">
    <ng-container *ngIf="!food.gallery">
      <button
        type="button"
        [ngClass]="{'classic-food-buttons-add': !isDesktop, 'classic-food-buttons-desktop': isDesktop}"
        class="btn btn-default btn-round icon-btn flex-content-vc-hc"
        (click)="addMeal(food)"
        (mouseover)="plusIconHover = true"
        (mouseleave)="plusIconHover = false"
      >
        <span
          class="icon-v2"
          [ngClass]="{'plus': plusIconHover || !isDesktop, 'plus-white opacity-50': !plusIconHover && isDesktop}"
        ></span>
      </button>

      <app-heart [food]="food" class="ml-4 w-32px"></app-heart>
    </ng-container>

    <a
      *ngIf="!food.googleImage"
      appHrefNone [ngClass]="{'ml-4': !food.gallery}" class="d-inline-flex mr-4"
      (click)="goToFoodGallery(food); $event.stopPropagation()"
    >
      <span class="icon-v2" [ngClass]="{'picture-white ml-n0-3rem': isDesktop, 'picture': !isDesktop}"></span>
    </a>

    <a *ngIf="!isDesktop && !house" appHrefNone class="d-inline-flex" (click)="shareFood()">
      <span class="icon-v2 share-dark"></span>
    </a>

    <div
      *ngIf="isDesktop"
      ngbDropdown
      appNgbDropdownOnHover
      placement="bottom-right"
    >
      <span ngbDropdownToggle class="hide-arrow cursor-pointer">
        <i class="icon share d-inline-flex"></i>
      </span>

      <div
        ngbDropdownMenu
        class="classic-food-buttons-menu dropdown-menu-share dropdown-share"
      >
        <a
          appHrefNone
          class="dropdown-item flex-content-vc text-white"
          (click)="shareFood('twitter')"
        >
          <span class="flex-fill">Tweet</span>

          <span class="icon-v2 twitter-white scale-07"></span>
        </a>

        <a
          appHrefNone
          class="dropdown-item flex-content-vc text-white"
          (click)="shareFood('facebook')"
        >
          <span class="flex-fill">Post</span>

          <span class="icon-v2 facebook-white scale-08"></span>
        </a>

        <a appHrefNone class="dropdown-item flex-content-vc text-white" (click)="copyLink()">
          <span class="flex-fill">Copy Link</span>

          <span class="icon-v2 chain-white scale-08"></span>
        </a>
      </div>
    </div>

    <div class="ml-auto flex-content-vc">
      <a
        *ngIf="instruction?.id > 0"
        appHrefNone
        class="d-inline-flex mr-3"
        (click)="onInstructionClick()"
      >
        <span class="icon-v2 instruction"></span>
      </a>

      <div *ngIf="!isDesktop && !house" class="hide-arrow cursor-pointer">
        <app-picker
          [picker]="foodAllergenPicker"
          [classes]="'icon dots-v'"
          [title]="food.title"
          (selectedValue)="optionSelected($event)"
        ></app-picker>
      </div>

      <div *appIfIsDesktop class="hide-arrow cursor-pointer mt-7px">
        <div class="icon-v2 dots-v-white" (mouseover)="clickOnAllergen = true"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #instructionsModalRef let-c="close">
  <app-instructions
    [instruction]="instruction"
    (changeInstruction)="onChangeInstruction($event)"
  ></app-instructions>
</ng-template>

<ng-template #allergensModalRef>
  <app-allergens-modal
    [food]="food"
    [allergens]="allergens"
    [restaurant]="restaurant"
    class="h-100"
  ></app-allergens-modal>
</ng-template>

<ng-template #restaurantModalRef>
  <app-restaurant-modal [restaurant]="restaurant" [sidenavRest]="true" class="h-100"></app-restaurant-modal>
</ng-template>

<ng-template #sectionModalRef>
  <div class="d-flex justify-content-center mt-1-65rem font-size-1-3rem font-weight-500"> {{ food.title }} </div>

  <div *ngIf="food.sections.length > 0" class="section section-margin">
    <div *ngFor="let section of food.sections" class="mt-4 text-dark">
      <input type="text" autofocus="autofocus" style="display:none"/>

      <app-section-view [section]="section"></app-section-view>
    </div>
  </div>

  <div class="m-3 d-flex justify-content-end">
    <button
      (click)="addClickEmit(food)"
      class="btn btn-dark btn-block classic-btn-add"
    >
      ADD
    </button>
  </div>
</ng-template>
