<div class="restaurant-modal-body p-0 h-100 w-100">
  <div *ngIf="isBasketReady && restaurantReady && !basket?.isEmpty()" class="d-flex flex-column w-100 h-100">
    <div class="d-flex h-100 bg-dark-on-modal">
      <div
        [appBackgroundUrl]="backgroundImage || ''"
        class="col-7 content-container h-100 d-flex flex-column p-0"
      >
        <div
          class="d-flex justify-content-end w-100 h-100 flex-column z-index-1 position-relative"
          [ngClass]="{
            'backdrop-filter-brightness-1': restaurantNameHovered,
            'backdrop-filter-brightness-0-3' : !restaurantNameHovered
          }"
        >
          <div
            class="text-white text-center"
            [ngClass]="{
              'restaurant-desktop-header-no-logo': !restaurant?.details.logo,
              'restaurant-desktop-header-logo': restaurant?.details.logo
            }"
            (mouseover)="restaurantNameHovered = true"
            (mouseleave)="restaurantNameHovered = false"
          >
            <div *ngIf="restaurant">
              <ng-container *ngIf="restaurant.details.logo">
                <img
                  appBlurImg
                  [path]="restaurant.details.logo.path"
                  [options]="{w: restaurant.details.logo.width, h: restaurant.details.logo.height}"
                  [title]="restaurant.nameView"
                  [alt]="restaurant.nameView"
                  class="logo-img cursor-pointer"
                  (click)="onMmmmClick()"
                />
              </ng-container>

              <ng-container *ngIf="!restaurant.details.logo">
                <div
                  class="restaurant-desktop-header-name cursor-pointer mx-5"
                  (click)="onMmmmClick()"
                >
                  {{
                    restaurant.nameView |
                      abbreviate:false:'' |
                      city: restaurant.cityNameToRemove
                  }}
                </div>

                <span *ngIf="restaurant.secondName" class="font-size-1-1rem">
                  {{restaurant.secondNameView}}
                </span>
              </ng-container>
            </div>
          </div>

          <!-- TODO SEARCH-> add loader before restaurant loading -->
          <div *ngIf="!restaurantNameHovered" class="restaurant-hours-desktop-content">
            <div
              *ngIf="restaurant.periodsLength"
              [ngClass]="{'rest-hours-padding': restaurant.details.logo}"
              class="position-relative text-white"
            >
              <app-restaurant-hours
                [restaurantHoursSections]="restaurant.hoursSectionsView"
                [isDesktop]="true"
                [isModal]="true"
                class="text-white"
              ></app-restaurant-hours>
            </div>
          </div>

          <div class="m-auto"></div>

          <div
            *ngIf="!restaurantNameHovered && basket.isEmpty()"
            class="pb-1 d-flex flex-column align-items-center"
          >
            <i class="icon-v2 stats opacity-30 cursor-pointer" (click)="statsClicked()"></i>
          </div>

          <div
            *ngIf="!restaurantNameHovered && basket.isEmpty()"
            class="font-size-1rem z-index-1 p-0 pb-3 text-white d-flex cursor-pointer"
            (click)="statsClicked()"
          >
            <span class="text-center w-100">{{ restaurant.statRating | shortNumberFormat }}</span>
          </div>

          <!-- TODO SEARCH-> add loader before restaurant loading -->
          <div
            [ngClass]="{'visibility-hidden': restaurantNameHovered}"
            class="flex-content-vc text-white text-center pb-3 preview-phone cursor-pointer"
          >
            <app-info-bar
              class="w-100"
              *ngIf="restaurant"
              [isRestaurantModal]="true"
              [restaurant]="restaurant"
            ></app-info-bar>
          </div>
        </div>
      </div>

      <div *ngIf="!basket.isEmpty() && !owner" class="col-5 font-size-1-1rem">
        <app-basket-modal [basket]="basket"></app-basket-modal>
      </div>
    </div>
  </div>

  <div
    *ngIf="restaurant && basket?.isEmpty()"
    class="h-100 w-100 bg-black d-flex flex-column overflow-hidden modal-content-wrapper"
  >
    <div class="w-100 text-white h-120px d-flex flex-column align-items-center justify-content-center mb-1 modal-header">
      <div class="restaurant-desktop-header-no-logo">
        <div class="d-flex align-items-center cursor-pointer">
          <div class="restaurant-name-container">
            <div
              class="restaurant-desktop-header-name text-shadow-70"
              (mouseover)="headerHover = true"
              (mouseleave)="headerHover = false"
              (click)="openGoogleMap()"
            >
              {{
                restaurant.nameView |
                  abbreviate:false:'' |
                  city: restaurant.cityNameToRemove
              }}
            </div>
          </div>

          <div *ngIf="!restaurant.isOwned" class="stats-container" (click)="statsClicked()">
            <i class="icon-v2 stats opacity-35 scale-085"></i>

            <span class="text-white font-size-0-8rem text-center">
              {{ restaurant?.statRating | shortNumberFormat }}
            </span>
          </div>
        </div>
      </div>

      <app-info-bar
        *ngIf="restaurant"
        [isModal]="true"
        [isRestaurantModal]="true"
        [typeCategorySection]=typeCategorySection
        [restaurant]="restaurant"
        [ngClass]="{'opacity-100': headerHover, 'opacity-45': !headerHover}"
        class="font-size-0-98rem"
        (mouseover)="headerHover = true"
        (mouseleave)="headerHover = false"
      ></app-info-bar>
    </div>

    <div class="modal-body">
      <div class="d-flex flex-column" style="height: calc(100vh - 300px) !important;">
        <div class="d-flex flex-grow-1 position-relative">
          <ng-container *ngIf="restaurant">
            <div
              *ngIf="!restaurant.slideGalleryFoods.empty"
              class="restaurant-preview-overlay-navigation position-absolute-0 z-index-10"
            >
              <div class="d-flex w-100 h-100 z-index-4 cursor-pointer">
                <div
                  class="restaurant-preview-swiper-navigation restaurant-preview-swiper-navigation-left"
                ></div>

                <div
                  class="restaurant-preview-swiper-navigation restaurant-preview-swiper-navigation-right"
                ></div>
              </div>
            </div>

            <swiper
              *ngIf="restaurant && !restaurant.slideGalleryFoods.empty"
              [config]="backgroundImagesConfig"
              class="h-100 w-100"
            >
              <!-- Slides -->
              <ng-template swiperSlide *ngFor="let topFood of restaurant.slideGalleryFoods.topFoods">
                <app-preview-modal-slide
                  [restaurant]="restaurant"
                  [backgroundImage]="backgroundImage"
                  [topFood]="topFood"
                  [hoursEnabled]="true"
                ></app-preview-modal-slide>
              </ng-template>

              <ng-template swiperSlide *ngIf="restaurant.slideGalleryFoods.outside">
                <app-preview-modal-slide
                  [restaurant]="restaurant"
                  [backgroundImage]="restaurant.slideGalleryFoods.outside.images[0].path | imgix"
                  [hoursEnabled]="true"
                ></app-preview-modal-slide>
              </ng-template>

              <ng-template swiperSlide *ngIf="restaurant.slideGalleryFoods.inside">
                <app-preview-modal-slide
                  [restaurant]="restaurant"
                  [backgroundImage]="restaurant.slideGalleryFoods.inside?.images[0].path | imgix"
                  [hoursEnabled]="true"
                ></app-preview-modal-slide>
              </ng-template>

              <ng-template swiperSlide *ngFor="let googleImage of restaurant.slideGalleryFoods.googleImages">
                <app-preview-modal-slide
                  [restaurant]="restaurant"
                  [backgroundImage]="googleImage.images[0].path | googleImage:360"
                  [hoursEnabled]="true"
                ></app-preview-modal-slide>
              </ng-template>
            </swiper>

            <div
              [ngClass]="{'visibility-hidden': restaurant.slideGalleryFoods.empty}"
              class="restaurant-preview-overlay-pagination w-100 d-flex justify-content-center align-items-end"
            >
              <div class="pointer-events-auto">
                <div
                  class="restaurant-preview-swiper-pagination"
                ></div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="d-flex w-100" style="min-height: 150px;">
        <div class="flex-fill text-dark d-flex justify-content-center footer-col mt-5 overflow-y">
          <app-restaurant-hours
            [restaurantHoursSections]="restaurant.hoursSectionsView"
            [isDesktop]="true"
            [isModal]="true"
            class="text-white"
          ></app-restaurant-hours>
        </div>

        <div class="flex-fill text-white footer-col cursor-pointer">
          <div
            *ngIf="restaurantEmbeds.length > 0"
            id="myVideo"
            class="text-center bg-black mt-3-5rem"
          >
            <ngx-simplebar #simpleBar class="w-100 bg-dark">
              <div *ngFor="let restaurantEmbed of restaurantEmbeds; let last = last;" [ngClass]="{'mb-3': !last}">
                <app-embed-video
                  *ngIf="restaurant"
                  [restaurant]="restaurant"
                  [embed]="restaurantEmbed"
                  (deleteEmbed)="onDeleteUserEmbed(restaurantEmbed)"
                >
                </app-embed-video>
              </div>
            </ngx-simplebar>
          </div>

          <div #modalContent>
            <ng-container *ngIf="needToShowVideo">
              <div class="bottom-section d-flex flex-column pt-5 mx-2rem">
                <div class="input-group">
                  <input
                    #videoInput
                    type="url"
                    class="form-control border-white box-shadow-none text-white"
                    placeholder="Paste a video link featuring {{ restaurant?.nameView | abbreviate: false: '' }}"
                    (input)="validateUrl($event)"
                  />

                  <span *ngIf="inputUrlValid" class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-primary waves-effect text-transform-none"
                      (click)="onVideoSubmitClick(videoInput.value)"
                    >
                      Submit
                    </button>
                  </span>
                </div>

                <div class="text-right">
                  <span class="float-left mt-2 text-white">
                    YouTube • TikTok • Rumble • etc
                  </span>

                  <button
                    type="button"
                    class="btn btn-default btn-link md-btn-flat waves-effect mr-n3"
                    (click)="restoreMenu()"
                  >
                    Cancel
                  </button>
                </div>
              </div>

              <div
                *ngIf="needToShowInstagram"
                class="bottom-section d-flex flex-column mx-2rem mb-6-5rem"
                [ngClass]="{'pt-3': !mmmmVideoMenu, 'pt-1-5rem': mmmmVideoMenu}"
              >
                <div class="input-group">
                  <input
                    #instagramInput
                    type="text"
                    required
                    minlength="3"
                    maxlength="30"
                    placeholder="Add Instagram username"
                    class="form-control border-white box-shadow-none text-white"
                    (input)="validateTextInput($event)"
                  />

                  <span *ngIf="inputTextValid" class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-primary waves-effect text-transform-none ig-submit-btn"
                      (click)="onInstagramSubmitClick(instagramInput.value)"
                    >
                      Submit
                    </button>
                  </span>
                </div>

                <div class="text-right">
                  <span class="float-left mt-2 text-white">
                    For {{ restaurant.secondNameView }}
                  </span>

                  <button
                    *ngIf="!mmmmVideoMenu"
                    type="button"
                    class="btn btn-default btn-link md-btn-flat waves-effect mr-n3"
                    (click)="restoreMenu()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </ng-container>
          </div>

          <div
            *ngIf="!needToShowVideo"
            class="text-white font-size-0-98rem mt-3-1rem mb-3-5rem flex-content-hc cursor-pointer z-index-10"
            (click)="onMMMMIconClick()"
          >
            Link video
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-desktop-modal-sidenav
    *ngIf="restaurant"
    [restaurant]="restaurant"
    [basket]="basket"
    [showOwnershipDetails]="owner"
    [basketChange]="basketClick"
    (instagramClicked)="onInstagramLinkClick()"
    (mmmmClicked)="onMmmmClick()"
    (videoMenuClicked)="onVideoMenuClick()"
    (instagramMenuClicked)="onInstagramMenuClick()"
    (ownerClick)="owner = $event"
    (basketChangeClick)="basketClick = $event"
    (sidenavHover)="sidenavHovered($event)"
    (click)="nextSlide()"
    (previousClick)="changeRestaurantToPrevious($event)"
    (nextClick)="changeRestaurantToNext($event)"
    [ngClass]="{'right-n44': !sidenavRest, 'right-n72': sidenavRest}"
    class="z-index-10"
  ></app-desktop-modal-sidenav>
</div>


<!--        <div-->
<!--          *ngIf="owner"-->
<!--          id="owner-container"-->
<!--          class="content-container flex-column d-flex h-100 position-relative"-->
<!--        >-->
<!--          <div-->
<!--            [ngClass]="{'d-none': sidenavHover}"-->
<!--            class="content-container h-100 d-flex flex-column bg-dark"-->
<!--          >-->
<!--            <div-->
<!--              class="d-flex justify-content-end w-100 h-100 flex-column z-index-1 position-relative backdrop-filter-brightness-0"-->
<!--            >-->
<!--              <div class="position-absolute-0 font-size-1rem d-flex flex-column">-->
<!--                <div-->
<!--                  [ngClass]="{'cursor-pointer': owner}"-->
<!--                  class="w-100 d-flex justify-content-center"-->
<!--                  (click)="owner = false"-->
<!--                >-->
<!--                  <div class="icon m-large-white scale-7 mt-7-4rem"></div>-->
<!--                </div>-->

<!--                <div-->
<!--                  [ngClass]="{'cursor-pointer': owner}"-->
<!--                  class="text-white w-100 d-flex justify-content-center mt-7rem"-->
<!--                  (click)="owner = false"-->
<!--                >-->
<!--                  The Mber community-->
<!--                </div>-->

<!--                <div-->
<!--                  [ngClass]="{'cursor-pointer': owner}"-->
<!--                  class="text-white w-100 d-flex justify-content-center mt-3"-->
<!--                  (click)="owner = false"-->
<!--                >-->
<!--                  M powers owners to share-->
<!--                </div>-->

<!--                <div-->
<!--                  [ngClass]="{'cursor-pointer': owner}"-->
<!--                  class="text-white w-100 d-flex justify-content-center mt-3"-->
<!--                  (click)="owner = false"-->
<!--                >-->
<!--                  * Complete menus with updates *-->
<!--                </div>-->

<!--                <div-->
<!--                  [ngClass]="{'cursor-pointer': owner}"-->
<!--                  class="text-white w-100 d-flex justify-content-center mt-3"-->
<!--                  (click)="owner = false"-->
<!--                >-->
<!--                  * Holiday hours & events *-->
<!--                </div>-->

<!--                <div-->
<!--                  [ngClass]="{'cursor-pointer': owner}"-->
<!--                  class="text-white w-100 d-flex justify-content-center mt-3"-->
<!--                  (click)="owner = false"-->
<!--                >-->
<!--                  * Allergen warnings *-->
<!--                </div>-->

<!--                <div [ngClass]="{'cursor-pointer': owner}" class="m-auto" (click)="owner = false"></div>-->

<!--                <input-->
<!--                  autofocus-->
<!--                  [ngClass]="{'cursor-pointer': owner}"-->
<!--                  class="visibility-hidden" (click)="owner = false"-->
<!--                >-->

<!--                <div class="w-100 d-flex justify-content-center pb-085-rem">-->
<!--                  <div-->
<!--                    #dropdownRef-->
<!--                    ngbDropdown-->
<!--                    appNgbDropdownOnHover-->
<!--                    placement="top-right"-->
<!--                    class="mr-1-5rem cursor-pointer"-->
<!--                  >-->
<!--                    <div-->
<!--                      ngbDropdownToggle-->
<!--                      class="text-white text-transform-none font-size-0-98rem opacity-35-to-1-on-hover hide-arrow"-->
<!--                    >-->
<!--                      Tell this owner-->
<!--                    </div>-->

<!--                    <div ngbDropdownMenu class="bg-dark backdrop-filter-blur-5">-->
<!--                      <a-->
<!--                        appHrefNone-->
<!--                        class="dropdown-item flex-content-vc"-->
<!--                        (click)="shareRestaurant('twitter')"-->
<!--                      >-->
<!--                        <span class="flex-fill text-white">Tweet</span>-->

<!--                        <span class="icon-v2 twitter-white scale-08"></span>-->
<!--                      </a>-->

<!--                      <a-->
<!--                        appHrefNone-->
<!--                        class="dropdown-item flex-content-vc"-->
<!--                        (click)="shareRestaurant('facebook')"-->
<!--                      >-->
<!--                        <span class="flex-fill text-white">Post</span>-->

<!--                        <span class="icon-v2 facebook-white scale-08"></span>-->
<!--                      </a>-->

<!--                      <a appHrefNone class="dropdown-item flex-content-vc" (click)="copyLink()">-->
<!--                        <span class="flex-fill text-white">Copy Link</span>-->

<!--                        <span class="icon-v2 chain-white scale-08"></span>-->
<!--                      </a>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div-->
<!--                    class="text-white text-transform-none cursor-pointer font-size-0-98rem opacity-35-to-1-on-hover"-->
<!--                    (click)="onOwnVerificationClicked()"-->
<!--                  >-->
<!--                    Get owner access-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
