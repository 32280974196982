<div
  #userAvatar
  class="user-avatar-container h-100 w-100 rounded-circle cursor-pointer"
  (click)="onImageClick()"
  (mouseover)="userAvatarHover = true"
  (mouseleave)="userAvatarHover = false"
>
  <div *ngIf="isImageUploading" class="image-loader-wrapper rounded-circle"></div>

  <img
    *ngIf="!isImageUploading && !isImageLoadError && user?.profileImage"
    [src]="user?.profileImage | imgix:{w: widthAndHeight, h: widthAndHeight, mask: 'ellipse'}"
    [alt]="user?.firstName + ' ' + user?.lastName"
    class="rounded-circle image-cover"
    (error)="isImageLoadError = true"
  />

  <div
    *ngIf="!isImageUploading && (isImageLoadError || !user?.profileImage)"
    class="user-avatar-none rounded-circle w-100 h-100 flex-content-vc-hc"
  >
    <ng-container *ngIf="icon">
      <i
        [ngStyle]="{zoom: userIconZoom, '-moz-transform': 'scale(' + userIconZoom +')'}"
        class="icon profile-2 align-self-center"
      ></i>
    </ng-container>

    <ng-container *ngIf="!icon">
      <span class="text-white" [ngStyle]="{fontSize: fontSize + 'px'}">
        {{ user?.firstName[0] }}{{ user?.lastName[0] }}
      </span>
    </ng-container>
  </div>

  <div *ngIf="userAvatarHover && userPage && !isSidebar" class="position-absolute-backdrop">
    <div class="backdrop-position cursor-pointer rounded-circle">
      <div class="position-camera-icon">
        <i class="icon image-camera-white scale-3"></i>
      </div>
    </div>
  </div>
</div>
