<ng-container *ngIf="countySelected?.hasRegions">
  <label *ngIf="position.isMobile" class="search-filters-mobile-label mb-0 d-block position-relative mr-2">
    <select
      #regionSelectRef
      required
      aria-label="Region"
      class="custom-select custom-select-mobile d-block w-100 hide-arrow"
      formControlName="regionId"
      (change)="onRegionChange($event.target)"
    >
      <option value="null" hidden [selected]="!regionSelected">Region</option>

      <ng-container *ngIf="countySelected">
        <option *ngFor="let option of regionSelect.options" [value]="option.value">
          {{ option.label }}
        </option>
      </ng-container>
    </select>
  </label>

  <div *ngIf="position.isDesktopNavbar" class="d-flex">
    <label class="mb-0 text-left mr-1-6rem">
      <ng-select
        #ngSelectRegionComponent
        appNgSelectOnHover
        [items]="regionSelect.options"
        [searchable]="false"
        [ngClass]="'ng-select-explore-search-title-under-logo dark'"
        class="ng-select-explore ng-select-dropdown auto-grow hide-arrow"
        bindLabel="label"
        bindValue="value"
        placeholder="Region"
        formControlName="regionId"
        (change)="onRegionChange($event)"
      ></ng-select>
    </label>
  </div>
</ng-container>
