<div class="modal-body h-100 w-100 d-flex p-0">
  <div
    class="content-container h-100 w-100 d-flex divider-background"
    [appBackgroundUrl]="backgroundImage"
  >
    <div class="divider-backdrop w-100 h-100 text-white px-2-5-rem d-flex flex-column">
      <div class="d-flex justify-content-center">
        <div class="mt-4 font-weight-bold section-title">
          <div>{{ typeCategorySection ? typeCategorySection.title : '' }}</div>

          <div class="city-name"> {{ cityName }} </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-desktop-modal-sidenav
  [isDivider]="true"
  (previousClick)="changeRestaurantToPrevious()"
  (nextClick)="changeRestaurantToNext()"
  class="z-index-10 right-n44"
></app-desktop-modal-sidenav>
