import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Restaurant } from '../../../domains/restaurant';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { User } from '../../../domains/user';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {
  @Input() user: User;
  @Input() widthAndHeight: number;
  @Input() icon = false;
  @Input() isImageUploading = false;
  @Input() isImageLoadError = false;
  @Input() userPage = false;
  @Input() restaurant: RestaurantSimple | Restaurant;
  @Input() isSidebar = false;

  @Output() imageClick = new EventEmitter<boolean>();

  @ViewChild('userAvatar', {static: true}) userAvatarElementRef: ElementRef;

  fontSize = 16;
  userIconZoom = 1;
  userAvatarHover = false;

  constructor() {
  }

  ngOnInit(): void {
    this.setInitialsSize();
  }

  @HostListener('window:resize')
  onResize() {
    this.setInitialsSize();
  }

  private setInitialsSize() {
    if (this.icon) {
      this.userIconZoom = this.userAvatarElementRef.nativeElement.offsetWidth / 50;
    } else {
      this.fontSize = this.userAvatarElementRef.nativeElement.offsetWidth / 2.3;
    }
  }

  onImageClick() {
    this.imageClick.emit(true);
  }
}
