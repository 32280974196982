<div
  [ngClass]="{'w-100': isDesktop}"
  class="flex-content-hc flex-column"
  (mouseover)="addressMouseOver = true"
  (mouseleave)="addressMouseOver = false"
>
  <div class="restaurant-desktop-address flex-content-hc cursor-pointer">
    <ng-container *ngIf="restaurant.city.airport">
      <a
        appHrefNone
        [ngClass]="{'font-size-0-98rem': isModal, 'font-size-1-1rem': !isModal}"
        class="text-white restaurant-desktop-address-item"
        (click)="openGoogleMap()"
      >
        {{ restaurant.city.display }}
      </a>
    </ng-container>

    <ng-container *ngIf="!restaurant.city.airport">
      <ng-container *ngIf="restaurant">
        <a
          appHrefNone
          [ngClass]="{'font-size-0-98rem': isModal, 'font-size-1-1rem': !isModal}"
          class="text-white restaurant-desktop-address-item"
          (click)="openInfoBar()"
        >
          {{ restaurant.address }}
        </a>

        <span [ngClass]="{
          'font-size-0-98rem': isModal && !restaurant.isOwned,
          'd-none': isModal && restaurant.isOwned || (!isModal && isDesktop),
          'font-size-1-1rem': !isModal
        }">&nbsp;•&nbsp;</span>

        <a
          appHrefNone
          [ngClass]="{
            'font-size-0-98rem': isModal && !restaurant.isOwned,
            'd-none': isModal && restaurant.isOwned || (!isModal && isDesktop),
            'font-size-1-1rem': !isModal
          }"
          class="text-white restaurant-desktop-address-item"
          (click)="openGoogleMap()"
        >
          {{ restaurant.cityAndState }}
        </a>

        <ng-container *ngIf="restaurant.nationalPhoneNumber">
          <span [ngClass]="{'font-size-0-98rem': isModal, 'font-size-1-1rem': !isModal}">&nbsp;•&nbsp;</span>

          <a
            appHrefNone
            [ngClass]="{'font-size-0-98rem': isModal, 'font-size-1-1rem': !isModal}"
            class="text-white restaurant-desktop-address-item"
            (click)="openGoogleMap()"
          >
            {{ restaurant.nationalPhoneNumber | mask: '(000) 000-0000' }}
          </a>
        </ng-container>
      </ng-container>
    </ng-container>
    <span *ngIf="!restaurant">&nbsp;</span>
  </div>
</div>
