<app-search-desktop-topnav
  *ngIf="!isSearching"
  [searchParams]="searchParams"
  [searchResult]="searchResult"
  [country]="country"
  [preview]="preview"
  [locationFavorites]="locationFavorites"
  (videoModalClicked)="openVideoModal()"
  (citySelected)="onCitySelected($event)"
  (previewChange)="onPreviewChange()"
  (locationDropdownChange)="onLocationDropdownChange()"
  (localitySelectedChange)="onLocalitySelectedChange($event)"
  (countyChange)="onCountyChange($event)"
  (stateChange)="onStateChange($event)"
></app-search-desktop-topnav>

<div *ngIf="isSidenavLeftOrRightMouseOver && !isSearchModalOpen" class="search-desktop-text d-flex z-index-10">
  <h1 class="text-white">Every restaurant as a photo journal</h1>
</div>

<div
  *ngIf="!areModalsOpen && !isFavoriteModalOpen && !isSearching && !isSidenavLeftOrRightMouseOver"
  class="search-desktop d-flex flex-column h-100"
>
  <div class="search-desktop-background search-desktop-background-active"></div>

  <div class="d-flex search-desktop-content d-flex flex-column h-100" (scroll)="onScroll()">
    <div
      *ngIf="!isLocalitySelected && !isSearching && dataLoaded && stateSelected && !countySelected && stateSelected.flagUrl"
      class="z-index-3 d-flex flex-column w-100 h-100vh"
    >
      <div class="d-flex flex-column align-items-center justify-content-center h-100vh">
        <img
          class="w-30"
          [src]="stateSelected?.flagUrl"
          [alt]="stateSelected?.display"
        >
      </div>
    </div>

    <ng-container *ngIf="!isSearching">
      <div class="search-desktop-main flex-fill overflow-auto main-preview">
        <div class="d-flex row">
          <app-restaurant-modal
            *ngIf="activeSearchResultSlide?.type === searchResultSlideType.RESTAURANT"
            [typeCategorySection]="activeTypeCategorySection"
            [restaurantExplore]="clickedRestaurant"
            [modalArrows]="true"
            [hasPrev]="hasPrev"
            [hasNext]="hasNext"
            (previousClick)="previousClick()"
            (nextClick)="nextClick()"
            class="col-10 pr-1_75rem z-index-10"
            style="height: calc(100vh - 58px);"
          ></app-restaurant-modal>

          <app-divider-modal
            *ngIf="activeSearchResultSlide?.type === searchResultSlideType.DIVIDER"
            [typeCategorySection]="activeTypeCategorySection"
            [cityName]="searchParams.locationName"
            [hasPrev]="hasPrev"
            [hasNext]="hasNext"
            (previousClick)="previousClick()"
            (nextClick)="nextClick()"
            class="col-10 pr-1_75rem"
            style="height: calc(100vh - 58px);"
          ></app-divider-modal>

          <div class="col-2 text-white pt-4 overflow-y element pr-0 pl-4" style="height: calc(100vh - 58px);">
            <ng-container *ngIf="searchResult.groupsFiltered && isCitySelected">
              <ngb-accordion #categoryAccordion="ngbAccordion" (panelChange)="onPanelChange($event)" [closeOthers]="true">
                <ng-container *ngFor="let group of searchResult.groupsFiltered;">
                  <ng-container *ngFor="let typeCategorySection of group.sections;">
                    <ng-container *ngIf="typeCategorySection.hasRestaurants">
                      <ngb-panel [id]="typeCategorySection.keysString" #categoryPanel>
                        <ng-template ngbPanelTitle>
                          <div
                            [ngClass]="{'active': typeCategorySection.active}"
                            class="py-2 pl-3 font-weight-400 opacity-45-to-1-on-hover"
                            (click)="categoryClick(typeCategorySection)"
                          >
                            {{ typeCategorySection.title }}
                          </div>
                        </ng-template>

                        <ng-template ngbPanelContent>
                          <ng-container *ngFor="let typeCategory of typeCategorySection.typeCategories;">
                            <ng-container *ngFor="let restaurant of typeCategory.restaurants; let i = index;">
                              <div
                                  [ngClass]="{
                                    'ml-5': restaurant !== clickedRestaurant,
                                    'ml-dot': restaurant === clickedRestaurant
                                  }"
                                class="mb-3 cursor-pointer d-flex"
                                (click)="openRestaurantModal(restaurant, typeCategorySection)"
                              >
                                <span *ngIf="restaurant === clickedRestaurant" class="dot mr-2 mt-2"></span>

                                <div> {{ restaurant.display | abbreviate:true:'' }} </div>
                              </div>
                            </ng-container>
                          </ng-container>
                        </ng-template>
                      </ngb-panel>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ngb-accordion>
            </ng-container>
          </div>
        </div>
      </div>

<!--      <div-->
<!--        *ngIf="!(preview || divider)"-->
<!--        class="search-desktop-sidebar overflow-auto bottom-0 text-white d-flex flex-column"-->
<!--      >-->
<!--        <div class="search-desktop-controls d-flex flex-column ">-->
<!--          <div class="search-desktop-controls-content d-flex mt-4-5rem">-->
<!--            <div class="d-flex flex-column search-desktop-restaurants">-->
<!--              <ng-container *ngIf="searchResult.groupsFiltered && isCitySelected">-->
<!--                <ng-container *ngFor="let group of searchResult.groupsFiltered; let sectionI = index;">-->
<!--                  <div-->
<!--                    class="search-desktop-section search-desktop-section-margin d-flex flex-column "-->
<!--                    [ngClass]="{'d-flex flex-column search-desktop-section-margin': sectionI != 0}"-->
<!--                  >-->
<!--                    <ng-container *ngFor="let typeCategorySection of group.sections; let i = index;">-->
<!--                      <div *ngIf="!typeCategorySection.isExample" class="search-desktop-section-title">-->
<!--                        <button-->
<!--                          [ngClass]="{-->
<!--                            'btn-active opacity-1': sectionActive && sectionActive == typeCategorySection,-->
<!--                            'opacity-0': !isLocalitySelected-->
<!--                          }"-->
<!--                          [disabled]="!typeCategorySection.hasRestaurants || !isLocalitySelected"-->
<!--                          class="btn btn-dark btn-primary-shadow md-btn-flat text-transform-none font-weight-400"-->
<!--                          (click)="typeCategorySectionSelect(typeCategorySection)"-->
<!--                        >-->
<!--                          {{ typeCategorySection.title }}-->
<!--                        </button>-->
<!--                      </div>-->
<!--                    </ng-container>-->
<!--                  </div>-->
<!--                </ng-container>-->
<!--              </ng-container>-->
<!--            </div>-->

<!--            <div-->
<!--              class="d-flex flex-column"-->
<!--              [ngClass]="{-->
<!--                'search-desktop-filters': isLocalitySelected-->
<!--              }"-->
<!--            >-->
<!--              <div-->
<!--                *ngIf="false"-->
<!--                [ngClass]="{'visibility-hidden': isCitySelected}"-->
<!--                class="search-desktop-add-share"-->
<!--              >-->
<!--                <a-->
<!--                  appHrefNone-->
<!--                  appOpacityFullOnHover-->
<!--                  class="d-inline-block text-white opacity-50 mb-2"-->
<!--                  (click)="addRestaurantDesktop()"-->
<!--                >-->
<!--                  Add-->
<!--                </a>-->

<!--                <br>-->

<!--                <div ngbDropdown>-->
<!--                  <a-->
<!--                    appHrefNone-->
<!--                    ngbDropdownToggle-->
<!--                    appOpacityFullOnHover-->
<!--                    class="d-inline-block text-white opacity-50 hide-arrow"-->
<!--                  >-->
<!--                    Share-->
<!--                  </a>-->

<!--                  <div ngbDropdownMenu>-->
<!--                    <a-->
<!--                      href="https://instagram.com/mmmm.menu"-->
<!--                      target="_blank"-->
<!--                      class="dropdown-item"-->
<!--                    >-->
<!--                      Instagram-->
<!--                    </a>-->

<!--                    <a appHrefNone class="dropdown-item" (click)="shareMMMM('twitter')">-->
<!--                      Twitter-->
<!--                    </a>-->

<!--                    <a appHrefNone class="dropdown-item" (click)="shareMMMM('facebook')">-->
<!--                      Facebook-->
<!--                    </a>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <!--        <ng-container *ngIf="searchResult.groupsFiltered?.length > 0 && isLocalitySelected && !(preview || divider)">-->
      <!--          <ng-container *ngFor="let group of searchResult.groupsFiltered; let sectionFirst = first;">-->
      <!--            <ng-container  *ngFor="let typeCategorySection of group.sections; let typeCategorySectionFirst = first;">-->
      <!--              <div-->
      <!--                #exploreEmpty-->
      <!--                *ngIf="typeCategorySection.hasChain || typeCategorySection.hasRestaurants"-->
      <!--                [attr.data-keys]="typeCategorySection.keysString"-->
      <!--                [ngClass]="{-->
      <!--                  'search-desktop-category-section-first': sectionFirst && typeCategorySectionFirst,-->
      <!--                  'search-desktop-category-section-example': typeCategorySection.isExample-->
      <!--                }"-->
      <!--                id="search-desktop-category-section-{{typeCategorySection.keysString}}"-->
      <!--                class="search-desktop-category-section"-->
      <!--              >-->
      <!--                <div-->
      <!--                  class="category-opacity flex-content-vc-hr text-uppercase text-white font-weight-600 cursor-pointer"-->
      <!--                  (click)="openDividerModal(typeCategorySection)"-->
      <!--                >-->
      <!--                  {{ typeCategorySection.title }}-->
      <!--                </div>-->

      <!--                <div-->
      <!--                  *ngFor="let typeCategory of typeCategorySection.typeCategories;"-->
      <!--                  class="search-desktop-category"-->
      <!--                >-->
      <!--                  <ng-container *ngFor="let restaurant of typeCategory.restaurants;">-->
      <!--                    <div-->
      <!--                      class="phantom-block-desktop phantom-block-desktop-explore"-->
      <!--                      id="restaurant-{{restaurant.id}}"-->
      <!--                    ></div>-->

      <!--                    <div class="search-desktop-restaurant">-->
      <!--                      <div class="flex-content-vc-hr">-->
      <!--                        <div class="search-desktop-restaurant-header d-flex justify-content-end">-->
      <!--                          <div class="flex-fill">-->
      <!--                            <a-->
      <!--                              appHrefNone-->
      <!--                              class="search-desktop-restaurant-name"-->
      <!--                              (click)="openRestaurantModal(restaurant, typeCategorySection)"-->
      <!--                              (mouseenter)="restaurant.hover = true"-->
      <!--                              (mouseleave)="restaurant.hover = false"-->
      <!--                            >-->
      <!--                              {{ restaurant.display | abbreviate:true:'' }}-->
      <!--                            </a>-->
      <!--                          </div>-->
      <!--                        </div>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </ng-container>-->
      <!--                </div>-->

      <!--                <div-->
      <!--                  *ngIf="!typeCategorySection.isExample"-->
      <!--                  class="category-position search-desktop-restaurant search-desktop-restaurant-header search-desktop-category"-->
      <!--                >-->
      <!--                  <a-->
      <!--                    [ngClass]="{-->
      <!--                      'opacity-35': !typeCategorySection.hasChain,-->
      <!--                      'cursor-pointer': typeCategorySection.hasChain-->
      <!--                    }"-->
      <!--                    (click)="openChainDesktopModal(typeCategorySection)"-->
      <!--                    class="icon-v2 chains-new"-->
      <!--                  ></a>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </ng-container>-->
      <!--          </ng-container>-->

      <!--          <div-->
      <!--            [ngClass]="{'opacity-0': !isLocalitySelected}"-->
      <!--            class="d-flex justify-content-end align-items-end mt-2rem"-->
      <!--          >-->
      <!--            <span-->
      <!--              class="cursor-pointer font-size-1-85rem text-white opacity-35-to-1-on-hover"-->
      <!--              (click)="addRestaurantDesktop()"-->
      <!--            >-->
      <!--              Add restaurant-->
      <!--            </span>-->
      <!--          </div>-->
      <!--        </ng-container>-->
    </ng-container>
  </div>
</div>

<ng-template #favoriteDesktopModalRef>
  <app-favorites-list
    [cityName]="searchParams.locationName"
    [favoriteList]="true"
    [locationFavorites]="locationFavorites"
    [searchResult]="searchResult"
    (favoriteStatusChanged)="onFavoriteStatusChange()"
    (desktopRestaurantNameClick)="openRestaurantModal($event, activeTypeCategorySection)"
  ></app-favorites-list>
</ng-template>

<ng-template #addRestaurantModalRef>
  <app-restaurant-add-modal></app-restaurant-add-modal>
</ng-template>

<ng-template #videoModalRef>
  <app-video-desktop-modal></app-video-desktop-modal>
</ng-template>

<!--<ng-template #videoDesktopModalRef let-c="close">-->
<!--  <app-video-modal-->
<!--    [searchParams]="searchParams"-->
<!--  ></app-video-modal>-->
<!--</ng-template>-->
