import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { KeyboardEvent } from 'react';
import { SimplebarAngularComponent } from 'simplebar-angular';
import Swiper, { Autoplay, EffectFade, Navigation, Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper_angular';
import { environment } from '../../../../environments/environment';
import { Auth } from '../../../decorators/auth.decorator';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { ModalClose } from '../../../decorators/modal-close.decorator';
import { Subscribe } from '../../../decorators/subscribe.decorator';
import { IgUsernameSuggestion } from '../../../domains/ig-username-suggestion';
import { Restaurant } from '../../../domains/restaurant';
import { RestaurantEmbed } from '../../../domains/restaurant-embed';
import { RestaurantExplore } from '../../../domains/restaurant-explore';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { Basket } from '../../../models/basket/basket';
import { EmbedType } from '../../../models/enums/embed-type';
import { ErrorResponseType } from '../../../models/enums/error-response-type';
import { ModalCloseReasons } from '../../../models/enums/modal-close-reasons';
import { SearchResult } from '../../../models/search-result';
import { TypeCategorySection } from '../../../models/type-category-section';
import { UnsplashImage } from '../../../models/unsplash-image';
import { BackgroundImageService } from '../../../services/background-image.service';
import { BasketService } from '../../../services/basket.service';
import { EmbedService } from '../../../services/embed.service';
import { ExploreService } from '../../../services/explore.service';
import { IgUsernameSuggestionService } from '../../../services/ig-username-suggestion.service';
import { NavigatorService } from '../../../services/navigator.service';
import { NotificationService } from '../../../services/notification.service';
import { RestaurantService } from '../../../services/restaurant.service';
import { RouterService } from '../../../services/router.service';
import { ShareService } from '../../../services/share.service';
import { ScriptLoaderUtils } from '../../../utils/script-loader-utils';

Swiper.use([Autoplay, Pagination, Navigation, EffectFade]);

@Component({
  selector: 'app-restaurant-modal',
  templateUrl: './restaurant-modal.component.html',
  styleUrls: ['./restaurant-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RestaurantModalComponent implements OnInit, OnDestroy, OnChanges {
  @Input() restaurantExplore: RestaurantExplore;
  @Input() restaurant: RestaurantSimple | Restaurant;
  @Input() modalArrows = false;
  @Input() typeCategorySection: TypeCategorySection;
  @Input() hasNext: boolean;
  @Input() hasPrev: boolean;
  @Input() sidenavRest = false;

  @Output() previousClick = new EventEmitter<boolean>();
  @Output() nextClick = new EventEmitter<boolean>();
  @Output() ownerChanged = new EventEmitter<boolean>();

  @ViewChild('videoInput') videoInput: ElementRef;
  @ViewChild('instagramInput') instagramInput: ElementRef;
  @ViewChild('simpleBar') simpleBar: SimplebarAngularComponent;
  @ViewChild('dropdownRef', {static: false, read: NgbDropdown}) dropdown: NgbDropdown;
  @ViewChild('modalContent') modalContent: ElementRef;
  @ViewChild('googleBackground') googleBackgroundElementRef: ElementRef;
  @ViewChild('restaurantBackgroundSwiper', { static: false }) restaurantBackgroundSwiper: SwiperComponent;

  @IsLoggedIn() isLoggedIn: boolean;

  searchResult: SearchResult;
  needToShowMenu = true;
  needToShowInstagram = false;
  needToShowVideo = false;
  inputUrlValid = false;
  inputTextValid = false;
  content: any;
  restaurantEmbeds: Array<RestaurantEmbed> = [];
  isVideoInputActive = false;
  igUsernameSuggestion: IgUsernameSuggestion;
  backgroundImage: UnsplashImage | string;
  dataLoaded = false;
  logoHovered = false;
  basket: Basket;
  isBasketReady = false;
  isRedirecting = false;
  mmmmVideoMenu = false;
  owner = false;
  restaurantReady = false;
  basketClick = true;
  sidenavHover = false;
  timeout: any;
  backgroundImagesAreReady = false;
  backgroundImagesConfig: SwiperOptions;
  headerHover = false;
  restaurantNameHovered = false;

  constructor(
    private navigatorService: NavigatorService,
    private notificationService: NotificationService,
    private userEmbedService: EmbedService,
    private restaurantService: RestaurantService,
    private igUsernameSuggestionService: IgUsernameSuggestionService,
    private basketService: BasketService,
    private routerService: RouterService,
    private exploreService: ExploreService,
    private backgroundImageService: BackgroundImageService,
    private logger: NGXLogger,
    private shareService: ShareService
  ) {
  }

  ngOnInit(): void {
    if (this.restaurantExplore) {
      this.getRestaurantFromExplore();
    } else {
      this.initialize();
    }
  }

  private getRestaurantFromExplore() {
    this.restaurantService.getRestaurantSimple(this.restaurantExplore.id).subscribe(restaurant => {
      this.restaurant = restaurant;

      this.restaurant.nextRestaurantId = this.restaurantExplore.nextRestaurantId;
      this.restaurant.previousRestaurantId = this.restaurantExplore.previousRestaurantId;

      this.initialize();
    });
  }

  startAutoplay() {
    this.restaurantBackgroundSwiper?.swiperRef.autoplay?.start();
  }

  stopAutoplay() {
    this.restaurantBackgroundSwiper?.swiperRef.autoplay?.stop();
  }

  nextSlide() {
    this.restaurantBackgroundSwiper?.swiperRef.slideNext();
  }

  ngOnDestroy() {
    this.routerService.setRestaurantHostnameAndLocationId(null, null);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'ArrowUp') {
      this.changeRestaurantToPrevious(this.restaurant);
    } else if (event.code === 'ArrowDown') {
      this.changeRestaurantToNext(this.restaurant);
    }
  }

  shareRestaurant(social: string = null) {
    this.shareService.shareRestaurant(this.restaurant, social);
  }

  copyLink() {
    navigator.clipboard.writeText(`https://${this.restaurant.hostname}.${environment.domain}`).then();
    this.notificationService.showCopied();
  }

  setRestaurant() {
    this.routerService.setRestaurantHostnameAndLocationId(this.restaurant.hostname, this.restaurant.locationIndex);
    this.basketService.setBasketLocalStorage();

    this.restaurant.periodsGroupAndSort();

    this.restaurantEmbeds = [];

    this.userEmbedService.getAllRestaurantEmbeds(this.restaurant.id).subscribe(userEmbeds => {
      this.restaurantEmbeds = userEmbeds;

      this.dataLoaded = true;

      setTimeout(() => {
        this.backgroundImagesAreReady = true;

        setTimeout(() => {
          this.stopAutoplay();
        }, 100);
      }, 500);

      this.modalContent?.nativeElement.scrollTo({top: 0, left: 0, behavior: 'auto'});
    });

    if (this.restaurant.backgroundImages.length === 0 && this.restaurant.googleBackgroundImages.length === 0) {
      this.backgroundImageService.getRestaurantBgImageOrRandom(this.restaurant, false).then(image => {
        this.backgroundImage = image;
      }).catch(e => {
        this.logger.error(e);
      });
    }

    setTimeout(() => {
      this.basketService.basketSubject.subscribe(value => {
        this.basket = value;

        this.isBasketReady = true;
      });
    }, 200);
  }

  onVideoMenuClick() {
    if (this.isLoggedIn && this.restaurantEmbeds.length === 0) {
      this.needToShowMenu = false;
      this.needToShowVideo = true;
      this.needToShowInstagram = false;
      this.mmmmVideoMenu = false;

      this.isVideoInputActive = true;

      setTimeout(() => {
        this.videoInput.nativeElement.focus();
      });
    } else if (this.isLoggedIn && this.restaurantEmbeds.length > 0) {
      this.needToShowMenu = false;
      this.needToShowVideo = true;
      this.needToShowInstagram = false;

      this.isVideoInputActive = true;

      setTimeout(() => {
        this.videoInput.nativeElement.focus();
      });
    } else {
      this.restaurantModalClose();
    }
  }

  @Auth()
  onMMMMIconClick() {
    if (this.isLoggedIn) {
      this.needToShowMenu = false;
      this.needToShowVideo = true;
      this.mmmmVideoMenu = true;

      if (!this.restaurant.instagram) {
        this.mmmmVideoMenu = true;

        this.needToShowInstagram = true;
      }

      this.isVideoInputActive = true;

      setTimeout(() => {
        this.videoInput.nativeElement.focus();
      });

      this.logoHovered = false;
    }
  }

  @ModalClose()
  @Auth()
  restaurantModalClose() {
  }

  @ModalClose()
  closeModal() {
  }

  @Auth()
  onInstagramMenuClick() {
    this.needToShowMenu = false;
    this.needToShowVideo = false;
    this.needToShowInstagram = true;
    this.mmmmVideoMenu = false;

    setTimeout(() => {
      this.instagramInput.nativeElement.focus();
    });
  }

  onInstagramLinkClick() {
    window.open(`https://instagram.com/${this.restaurant.instagram}`, '_blank');
  }

  restoreMenu() {
    this.needToShowMenu = true;
    this.needToShowInstagram = false;
    this.needToShowVideo = false;

    this.isVideoInputActive = false;
  }

  validateUrl(event) {
    if (!event.target.value) {
      return false;
    }

    this.inputUrlValid = event.target.checkValidity();
  }

  validateTextInput(event) {
    this.inputTextValid = event.target.checkValidity();
  }

  onVideoSubmitClick(url) {
    const userEmbed = new RestaurantEmbed();

    userEmbed.url = url;
    userEmbed.restaurantId = this.restaurant.id;

    this.userEmbedService.add(userEmbed, EmbedType.RESTAURANT).subscribe({
      next: async value => {
        this.addVideoToList(value);
        this.restoreMenu();

        setTimeout(() => {
          const videoContainer = document.getElementById('myVideo');
          videoContainer.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
        }, 100);
      }, error: error => {
        if (error.error === 'Internal Server Error') {
          this.notificationService.embedError();
        } else if (error.error.type === ErrorResponseType.AlreadyExistsException) {
          this.notificationService.videoAlreadyExists();
        } else {
          this.notificationService.embedError();
        }
      }
    }).add(() => {
      this.restaurantEmbeds.forEach(embed => {
        if (embed.html.indexOf('<script') !== -1) {
          embed.isVertical = true;

          ScriptLoaderUtils.loadScriptFromHtml(embed.html, embed.providerName).then();
        }
      });
    });

    this.needToShowVideo = false;
  }

  onInstagramSubmitClick(value) {
    this.igUsernameSuggestion = this.igUsernameSuggestionService.createNewSuggestionObject(this.restaurant.id, value);

    this.restoreMenu();

    this.igUsernameSuggestionService.add(this.igUsernameSuggestion).subscribe(val => {
      this.igUsernameSuggestion = val;
    });
  }

  onCancelInstagram(igUsernameSuggestion: IgUsernameSuggestion) {
    this.igUsernameSuggestionService.delete(igUsernameSuggestion)
      .subscribe(() => {
        this.igUsernameSuggestion = null;
      });
  }

  onMmmmClick() {
    if (this.restaurant.published) {
      this.goToRestaurant();
    }
  }

  @ModalClose({reason: ModalCloseReasons.NEXT_NAVIGATE})
  goToRestaurant() {
    this.navigatorService.goToRestaurant(this.restaurant.hostname, {'first-menu': true});
  }

  onDeleteUserEmbed(restaurantEmbed: RestaurantEmbed) {
    this.restaurantEmbeds = this.restaurantEmbeds.filter(value => value.id !== restaurantEmbed.id);
  }

  private addVideoToList(restaurantEmbed: RestaurantEmbed) {
    if (restaurantEmbed.html.indexOf('<script') !== -1) {
      restaurantEmbed.isVertical = true;

      ScriptLoaderUtils.loadScriptFromHtml(restaurantEmbed.html, restaurantEmbed.providerName).then(() => {
        this.restaurantEmbeds.unshift(restaurantEmbed);
      });
    } else {
      this.restaurantEmbeds.unshift(restaurantEmbed);
    }
  }

  @Auth()
  onOwnVerificationClicked() {
    setTimeout(() => {
      this.restaurantService.verifyOwnershipEmail(this.restaurant.hostname).subscribe(() => {
        this.notificationService.claimOwnerMessage();
      });
    }, 200);
  }

  @Auth()
  @Subscribe()
  openMbershipModal() {
  }

  changeRestaurantToPrevious(restaurant: RestaurantSimple) {
    this.changeRestaurant(restaurant, true);

    if (this.owner) {
      this.owner = false;
    }

    this.previousClick.emit(true);

    this.restoreMenu();
  }

  changeRestaurantToNext(restaurant: RestaurantSimple) {
    this.changeRestaurant(restaurant, false);

    if (this.owner) {
      this.owner = false;
    }

    this.nextClick.emit(true);

    this.restoreMenu();
  }

  private initialize() {
    if (this.isLoggedIn) {
      this.igUsernameSuggestionService.getCurrentUserSuggestion(this.restaurant.id)
        .subscribe(value => {
          if (value) {
            if (value.suggestion[0] === '@') {
              value.suggestion = value.suggestion.substring(1);
            }

            this.igUsernameSuggestion = value;
          }
        });
    } else {
      this.igUsernameSuggestion = null;
    }

    this.exploreService.searchResultSubject.subscribe(value => {
      this.searchResult = value;

      if (this.searchResult && (!this.restaurant.previousRestaurantId || this.restaurant.nextRestaurantId)) {
        const restaurantFromSearchResult = this.searchResult.restaurants
          .find(restaurantI => restaurantI.id === this.restaurant.id);

        if (restaurantFromSearchResult !== undefined) {
          this.restaurant.previousRestaurantId = restaurantFromSearchResult.previousRestaurantId;
          this.restaurant.nextRestaurantId = restaurantFromSearchResult.nextRestaurantId;
        }
      }
    });

    this.setRestaurant();

    this.restaurantReady = true;

    this.backgroundImagesConfig = {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 0,
      pagination: {
        clickable: true,
        el: '.restaurant-preview-swiper-pagination',
        renderBullet: (index, className) => {
          return `<span class="swiper-pagination-bullet"></span>`;
        },
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      noSwiping: true,
      allowTouchMove: false,
      navigation: {
        enabled: true,
        prevEl: '.restaurant-preview-swiper-navigation-left',
        nextEl: '.restaurant-preview-swiper-navigation-right',
      },
      loop: true
    };
  }

  private changeRestaurant(restaurant: RestaurantSimple, toPrevious: boolean) {
    if (!restaurant.previousRestaurantId && !restaurant.nextRestaurantId) {
      this.isRedirecting = true;

      this.navigatorService.goToUrl(`${this.restaurant.locationSearchPath}/restaurants`).then(() => {
        this.isRedirecting = false;
      });
    } else {
      const restaurantToChangeId = toPrevious ?
        this.restaurant.previousRestaurantId :
        this.restaurant.nextRestaurantId;

      if (restaurantToChangeId && this.searchResult) {
        const restaurantToChange = this.searchResult.restaurants
          .find(restaurantI => restaurantI.id === restaurantToChangeId);

        if (restaurantToChange !== undefined) {
          this.restaurant = null;

          this.googleBackgroundElementRef?.nativeElement.classList.remove('google-background-container');

          this.restaurantExplore = restaurantToChange;

          this.restaurantService.getRestaurantSimple(restaurantToChange.id).subscribe((value) => {
            this.restaurant = value;

            this.restaurant.previousRestaurantId = restaurantToChange.previousRestaurantId;
            this.restaurant.nextRestaurantId = restaurantToChange.nextRestaurantId;

            this.setRestaurant();
          });
        }
      }
    }
  }

  statsClicked() {
    this.notificationService.statsToastr();
  }

  sidenavHovered(hovered: boolean) {
    this.sidenavHover = hovered;

    if (hovered) {
      this.startAutoplay();
    } else {
      this.stopAutoplay();
    }
  }

  openGoogleMap() {
    this.navigatorService.goToMapsRestaurant(this.restaurant);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('restaurantExplore') && changes.restaurantExplore.previousValue) {
      this.getRestaurantFromExplore();
    }
  }
}
