import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { Auth } from '../../../decorators/auth.decorator';
import { ModalClose } from '../../../decorators/modal-close.decorator';
import { Subscribe } from '../../../decorators/subscribe.decorator';
import { Restaurant } from '../../../domains/restaurant';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { Basket } from '../../../models/basket/basket';
import { ModalType } from '../../../models/enums/modal-type';
import { RestaurantPickerOption } from '../../../models/enums/restaurant-picker-option';
import { PickerGroup } from '../../../models/picker-group';
import { PickerOption } from '../../../models/picker-option';
import { ModalService } from '../../../services/modal.service';
import { NavigatorService } from '../../../services/navigator.service';
import { NotificationService } from '../../../services/notification.service';
import { RestaurantService } from '../../../services/restaurant.service';
import { ShareService } from '../../../services/share.service';

@Component({
  selector: 'app-desktop-modal-sidenav',
  templateUrl: './desktop-modal-sidenav.component.html',
  styleUrls: ['./desktop-modal-sidenav.component.scss']
})
export class DesktopModalSidenavComponent implements OnInit, OnChanges {
  @Input() restaurant: Restaurant | RestaurantSimple;
  @Input() isChain = false;
  @Input() isDivider = false;
  @Input() showOwnershipDetails = false;
  @Input() basket: Basket;
  @Input() basketChange = false;

  @Output() instagramClicked = new EventEmitter<boolean>();
  @Output() mmmmClicked = new EventEmitter<boolean>();
  @Output() directionClicked = new EventEmitter<boolean>();
  @Output() instagramMenuClicked = new EventEmitter<boolean>();
  @Output() videoMenuClicked = new EventEmitter<boolean>();
  @Output() ownerClick = new EventEmitter<boolean>();
  @Output() isHover = new EventEmitter<boolean>();
  @Output() previousClick = new EventEmitter<RestaurantSimple>();
  @Output() nextClick = new EventEmitter<RestaurantSimple>();
  @Output() basketChangeClick = new EventEmitter<boolean>();
  @Output() sidenavHover = new EventEmitter<boolean>();

  @ViewChild('dropdownRef', {static: false, read: NgbDropdown}) dropdown: NgbDropdown;
  @ViewChild('videoReportModalRef') videoReportModalRef: ElementRef;

  logoMouseOver = false;
  menus: Array<PickerGroup>;

  constructor(
    private restaurantService: RestaurantService,
    private navigatorService: NavigatorService,
    private notificationService: NotificationService,
    private shareService: ShareService,
    private modalService: ModalService
  ) {
  }

  ngOnInit(): void {
    if (this.restaurant) {
      this.initRestaurantMenus(this.restaurant);
    }
  }

  settingsAndCameraClick() {
    this.notificationService.settingsAndCameraClicked();
  }

  onClickCamera() {
    if (this.restaurant.isOwned) {
      this.settingsAndCameraClick();
    } else {
      this.notificationService.cameraDesktop();
    }
  }

  imageSearch(restaurant) {
    this.navigatorService.goToGoogleImagesSearch(restaurant);
  }

  googlePhotos(restaurant) {
    this.navigatorService.goToGoogleImages(restaurant);
  }

  openEditModal() {
    this.modalService.open(this.videoReportModalRef, ModalType.LOGIN);
  }

  @Auth()
  onOwnVerificationClicked(value: boolean) {
    this.ownerClick.emit(value);
  }

  @ModalClose()
  goToRestaurant() {
    window.open('/' + this.restaurant.hostname, '_blank');
  }

  @ModalClose()
  goToHouse() {
    window.open('/' + this.restaurant.hostname + '/house', '_blank');
  }

  shareRestaurant(social: string = null) {
    this.shareService.shareRestaurant(this.restaurant, social);
  }

  shareCity(restaurant, social: string = null) {
    this.shareService.shareCity(this.restaurant, social);
  }

  copyLink() {
    navigator.clipboard.writeText(`https://${this.restaurant.hostname}.${environment.domain}`).then();
    this.notificationService.showCopied();
  }

  closeDropdown() {
    this.dropdown.close();
  }

  shareSocial(isChain = false, restaurant, social) {
    if (isChain) {
      this.shareCity(restaurant, social);
    } else {
      this.shareRestaurant(social);
    }
  }

  basketChanged(value?: boolean) {
    this.basketChange = value;

    this.basketChangeClick.emit(value);
  }

  onSidenavHover(value: boolean) {
    this.sidenavHover.emit(value);

    this.logoMouseOver = value;
  }

  @Auth()
  @Subscribe()
  openMbershipModal() {
  }

  @ModalClose()
  onClickFood(menu: PickerOption) {
    window.open('/' + this.restaurant.hostname + menu.value, '_blank');
  }

  @ModalClose()
  pictureClick() {
    this.navigatorService.goToRestaurant(this.restaurant.hostname, {'first-menu': true});
  }

  onPreviousClick(restaurant: RestaurantSimple) {
    this.previousClick.emit(restaurant);
  }

  onNextClick(restaurant: RestaurantSimple) {
    this.nextClick.emit(restaurant);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('restaurant')) {
      this.initRestaurantMenus(changes.restaurant.currentValue);
    }
  }

  private initRestaurantMenus(restaurant: Restaurant|RestaurantSimple) {
    this.restaurant = restaurant;

    this.restaurantService.getRestaurantMenus(this.restaurant.id)
      .subscribe(pickerMenus => {
        this.menus = pickerMenus;

        const linksGroup = new PickerGroup('Links');
        linksGroup.addOption(RestaurantPickerOption.IMAGE_SEARCH, 'Web images');
        linksGroup.addOption(RestaurantPickerOption.MAP_PHOTO, 'Photos | Map');
      });
  }
}
