<div class="rest-preview-sidenav h-100 w-100 cursor-pointer">
  <div class="h-100 flex-content-hr">
    <div
      class="rest-preview-sidenav-menu text-white d-flex flex-column sidenav-icons"
      (mouseover)="onSidenavHover(true)"
      (mouseleave)="onSidenavHover(false)"
    >
      <div [ngClass]="{'visibility-hidden': isDivider}" class="flex-content-hc mb-2-5rem menu-item">
        <i class="icon image-camera-white" (click)="onClickCamera()"></i>
      </div>

      <div
        *ngIf="!isChain && restaurant?.isOwned"
        [ngClass]="{'visibility-hidden': isDivider}"
        class="flex-content-hc mb-2-5rem hide-arrow"
        (click)="settingsAndCameraClick()"
      >
        <i class="icon-v2 selector-button"></i>
      </div>

      <div
        *ngIf="!isChain && !restaurant?.isOwned"
        ngbDropdown
        appNgbDropdownOnHover
        [ngClass]="{'visibility-hidden': isDivider}"
        [autoClose]="'inside'"
        placement="bottom-right"

      >
        <div ngbDropdownToggle class="flex-content-hc mb-2-5rem hide-arrow">
          <i class="icon-v2 selector-button"></i>
        </div>

        <div ngbDropdownMenu class="bg-dark backdrop-filter-blur-5">
          <a appHrefNone class="dropdown-item" (click)="imageSearch(restaurant)">
            Image search
          </a>

          <a appHrefNone class="dropdown-item" (click)="videoMenuClicked.emit(true)">
            Link video
          </a>

          <a
            appHrefNone class="dropdown-item"
            (click)="openEditModal()"
          >
            Edit
          </a>

          <a
            *ngIf="!(restaurant | isRestaurantOwned)"
            appHrefNone
            class="dropdown-item"
            (click)="onOwnVerificationClicked(true)"
          >
            Owner
          </a>
        </div>
      </div>

      <div
        #dropdownRef
        ngbDropdown
        appNgbDropdownOnHover
        placement="bottom-right"
        [ngClass]="{'visibility-hidden': isDivider}"
        (mouseleave)="closeDropdown()"
      >
        <div ngbDropdownToggle class="flex-content-hc mb-2-5rem hide-arrow">
          <i class="icon share"></i>
        </div>

        <div ngbDropdownMenu class="bg-dark backdrop-filter-blur-5">
          <a
            appHrefNone
            class="dropdown-item flex-content-vc"
            (click)="shareSocial(isChain, restaurant, 'twitter')"
          >
            <span class="flex-fill">Tweet</span>

            <span class="icon-v2 twitter-white scale-08"></span>
          </a>

          <a
            appHrefNone
            class="dropdown-item flex-content-vc"
            (click)="shareSocial(isChain, restaurant,'facebook')"
          >
            <span class="flex-fill">Post</span>

            <span class="icon-v2 facebook-white scale-08"></span>
          </a>

          <a appHrefNone class="dropdown-item flex-content-vc" (click)="copyLink()">
            <span class="flex-fill">Copy Link</span>

            <span class="icon-v2 chain-white scale-08"></span>
          </a>
        </div>
      </div>

      <div
        *ngIf="restaurant?.published"
        #dropdownRef
        ngbDropdown
        appNgbDropdownOnHover
        placement="bottom-right"
        [ngClass]="{'visibility-hidden': isDivider}"
        (mouseleave)="closeDropdown()"
      >
        <div ngbDropdownToggle class="flex-content-hc mb-2-5rem hide-arrow">
          <i class="icon m-large-white scale-09"></i>
        </div>

        <div
          ngbDropdownMenu
          class="bg-dark backdrop-filter-blur-5"
        >
          <ng-container *ngFor="let menu of menus">
            <a *ngIf="menu.label" appHrefNone class="dropdown-header">
              {{ menu.label }}
            </a>

            <a
              appHrefNone
              *ngFor="let menuTitle of menu.options; "
              class="dropdown-item flex-content-vc"
              (click)="onClickFood(menuTitle)"
            >
              {{ menuTitle.label }}
            </a>
          </ng-container>
        </div>
      </div>

      <div
        *ngIf="!restaurant?.published"
        [ngClass]="{'visibility-hidden': isDivider}"
        class="flex-content-hc mb-2-5rem hide-arrow"
        (click)="pictureClick()"
      >
        <i class="icon-v2 picture-white"></i>
      </div>

      <div
        ngbTooltip="Try keyboard arrows!"
        placement="bottom-right"
        class="flex-content-hc mb-2-5rem cursor-pointer"
        (click)="onPreviousClick(restaurant)"
      >
        <i class="icon-v2 arrow-circle-white rotate-180"></i>
      </div>

      <div
        class="flex-content-hc mb-2-5rem cursor-pointer"
        (click)="onNextClick(restaurant)"
      >
        <i class="icon-v2 arrow-circle-white"></i>
      </div>

      <div
        *ngIf="restaurant?.instagram"
        [ngClass]="{'visibility-hidden': isDivider}"
        class="flex-content-hc mb-2-5rem menu-item"
      >
        <i class="icon-v2 instagram-white" (click)="instagramClicked.emit(true)"></i>
      </div>

      <div
        *ngIf="!basket?.isEmpty() && basketChange"
        [ngClass]="{'visibility-hidden': isDivider}"
        class="flex-content-hc mb-2-5rem menu-item"
        (click)="basketChanged(false)"
      >
        <i class="icon-v2 video-white-button"></i>
      </div>
    </div>
  </div>
</div>

<ng-template #videoReportModalRef>
  <app-video-report-modal
    [restaurant]="restaurant"
  ></app-video-report-modal>
</ng-template>
